@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Variable
// ==================================================
$body-min-width-pc: 1360px;
$navigation-width-pc: 320px;
$transition-setting: 0.3s ease;

// ==================================================
//  Hide
// ==================================================
.hide {
  &-pc {
    @include mixin.pc {
      display: none !important;
    }
  }
  &-sp {
    @include mixin.sp {
      display: none !important;
    }
  }
}

// ==================================================
//  Body
// ==================================================
body {
  font-family: "Kiwi Maru", serif;
  font-size: 1.6rem;
  letter-spacing: -0.081em;
  font-weight: 500;
  line-height: calc(25 / 16);
  min-width: $body-min-width-pc;
  color: color.$site-write;
  background-color: color.$site-bg;

  @include mixin.sp {
    font-size: 1.4rem;
    min-width: 375px;
  }
}

// ==================================================
//  Header
// ==================================================
.header {
  display: block;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;

  @include mixin.pc {
    width: $navigation-width-pc;
    height: 100%;
    background-color: color.$site-bg;
  }

  @include mixin.sp {
    width: 100%;
  }

  &_inner {
    display: block;
    width: 100%;
    height: calc(100% - 41px);
    position: relative;
    overflow: auto;

    @include mixin.pc {
      max-width: 223px;
      max-height: 759px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include mixin.sp {
      max-width: none;
      height: auto;
    }
  }

  &_logo {
    display: block;
    width: 100%;
    max-width: 185px;
    margin: 0 auto;

    @include mixin.sp {
      max-width: 110px;
      padding: 15px;
      background-color: color.$site-bg;
      margin-left: 20px;
      margin-right: auto;
      position: relative;
      z-index: 2;
    }

    a,
    img {
      display: block;
      width: 100%;
    }

    a {
      @include mixin.hoverOpacity60;
    }
  }

  &_navigation {
    display: block;
    width: 100%;
    margin: 45px auto 0;

    @include mixin.pc {
      max-width: 187px;
    }

    @include mixin.sp {
      width: 100%;
      height: 100%;
      margin: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: color.$site-bg;
      padding: 145px 40px 40px;
    }

    // Javascript
    &.header_menu-js {
      @include mixin.sp {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }

      // Modifier
      &-active {
        @include mixin.sp {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_menu {
      display: block;
      width: 100%;

      &_list {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;

        &_item {
          display: block;
          font-size: 1.6rem;
          line-height: calc(24 / 16);
          border-bottom: 3px solid color.$write-white;

          @include mixin.sp {
            font-size: 2rem;
            border-bottom: none;
          }

          &_inner {
            display: block;
            padding: 7px 0 8px;
            text-decoration: none;
            @include mixin.hoverOpacity60;

            @include mixin.sp {
              display: flex;
              align-items: center;
              column-gap: 12px;
              padding: 16px 0;
            }
          }

          &_textEn {
            display: block;
            font-size: 1.2rem;
            line-height: calc(16 / 11);

            @include mixin.sp {
              font-size: 1.3rem;
              margin: 2px 0 0 2px;
            }
          }
        }
      }
    }

    &_linkButton {
      width: 100%;
      max-width: 190px;
      margin: 26px auto 0;
      font-size: 1.5rem;
      line-height: calc(21 / 15);
      text-align: center;
      display: block;
      color: color.$write-white;
      background-color: color.$site-write;
      border-radius: 27px;
      padding: 16px 0 15px;
      text-decoration: none;
      position: relative;
      transform: translateX(-8px);

      @include mixin.sp {
        min-width: 240px;
        margin-top: 25px;
        font-size: 1.6rem;
        padding: 12px 24px 13px;
      }

      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: auto;
        aspect-ratio: 1/0.9;
        background-image: url(../img/common/ico_cart.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        transform: translateY(-2px);
        margin-right: 8px;

        @include mixin.sp {
          width: 18px;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 25px;
        height: 2px;
        border-radius: 13px;
        background-color: color.$site-write;
        position: absolute;
        top: 50%;
        right: -13px;
        transition: right $transition-setting;

        @include mixin.sp {
          right: -12px;
        }
      }

      @include mixin.hover {
        &::after {
          right: -23px;
        }
      }
    }

    &_sns {
      display: flex;
      flex-wrap: wrap;
      column-gap: 6px;
      row-gap: 6px;
      margin: 70px 0 0 5px;

      @include mixin.sp {
        justify-content: center;
        column-gap: 15px;
        margin: 30px 0 0;
      }

      &_item {
        display: block;
        width: 34px;
        height: auto;
        text-decoration: none;
        @include mixin.hoverOpacity60;

        @include mixin.sp {
          width: 36px;
        }

        & > img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  &_menuButton {
    display: none;

    @include mixin.sp {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 65px;
      height: auto;
      aspect-ratio: 1/1;
      color: color.$write-white;
      background-color: color.$site-write;
      font-family: sans-serif;
      font-weight: 600;
      font-size: 1.3rem;
      padding: 0 0 6px 0;
      position: absolute;
      z-index: 3;
      top: 0;
      right: 0;
      background-image: url(../img/common/icon_menu.svg);
      background-position: center top 13px;
      background-repeat: no-repeat;
      background-size: 30px auto;
    }

    // Javascript
    &-js {
      // Modifier
      &-active {
        @include mixin.sp {
          background-image: url(../img/common/icon_menu_close.svg);
          background-position: center top 11px;
          background-size: 25px auto;
        }
      }
    }
  }
}

// ==================================================
//  Main
// ==================================================
.main {
  width: calc(100% - $navigation-width-pc);
  background-color: color.$bg-white;
  margin-left: auto;

  // Media Query
  @media screen and (min-width: 1400px) {
    padding-right: 180px;

    .main_contentInner {
      margin: 0 auto;
    }
  }

  @include mixin.sp {
    width: 100%;
  }

  // Content Inner
  &_contentInner {
    display: flex;
    width: 100%;
    max-width: 900px;
    column-gap: 34px;

    @include mixin.sp {
      flex-direction: column;
      max-width: calc(100% - 40px);
      column-gap: 0;
      margin: 0 auto;
    }

    &_title {
      display: block;
      min-width: 74px;
      position: relative;

      @include mixin.sp {
        min-width: 0;
        text-align: center;
        margin-bottom: 30px;
      }

      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background: rgb(240, 119, 119);
        background: linear-gradient(180deg, rgba(240, 119, 119, 1) 0%, rgba(255, 255, 255, 1) 100%);
        position: absolute;
        top: 0;
        right: 0;

        @include mixin.sp {
          display: none;
        }
      }

      &_text {
        display: block;
        font-size: 2.1rem;
        line-height: calc(30 / 21);
        margin: 0 0 0 auto;

        @include mixin.pc {
          writing-mode: vertical-rl;
        }

        @include mixin.sp {
          font-size: 2.2rem;
          margin: 0;
        }
      }
    }

    &_content {
      display: block;
      width: 100%;

      @include mixin.pc {
        word-wrap: break-word;
      }
    }
  }

  // Button
  &_linkButton {
    display: block;
    width: 100%;

    &_item {
      min-width: 280px;
      margin-top: 78px;
      font-size: 2rem;
      line-height: calc(29 / 20);
      text-align: center;
      display: inline-block;
      color: color.$write-white;
      background-color: color.$site-write;
      border-radius: 34px;
      padding: 14px 30px 15px;
      text-decoration: none;
      position: relative;

      @include mixin.sp {
        min-width: 240px;
        margin-top: 40px;
        font-size: 1.6rem;
        padding: 12px 24px 13px;
      }

      &::after {
        content: "";
        display: block;
        width: 25px;
        height: 2px;
        border-radius: 13px;
        background-color: color.$site-write;
        position: absolute;
        top: 50%;
        right: -13px;
        transition: right $transition-setting;

        @include mixin.sp {
          right: -12px;
        }
      }

      @include mixin.hover {
        &::after {
          right: -23px;
        }
      }
    }
  }
}

// ==================================================
//  Footer
// ==================================================
.footer {
  display: block;
  width: 100%;
  padding: 176px 0 53px;
  width: calc(100% - $navigation-width-pc);
  background-color: color.$bg-white;
  margin-top: 689px;
  margin-left: auto;

  @include mixin.sp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0 20px;
    width: 100%;
    height: 100vh;
    min-height: 667px;
    margin-top: 560px;
  }

  &_lead {
    font-size: 4rem;
    line-height: calc(66 / 40);
    text-align: center;
    margin: 0;

    @include mixin.sp {
      font-size: 2.3rem;
    }

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      transform: translateY(-4px);
      width: 37px;
      height: 3px;
      border-radius: 18px;
      background-color: color.$site-write;
      margin-left: 9px;

      @include mixin.sp {
        width: 24px;
        height: 2.5px;
        margin-left: 6px;
        transform: translateY(-3px);
      }
    }
  }

  &_symbol {
    display: block;
    width: 100%;
    max-width: 205px;
    margin: 107px auto 0;

    @include mixin.sp {
      max-width: 150px;
      margin-top: 40px;
    }
  }

  &_copyright {
    font-size: 1.6rem;
    line-height: calc(24 / 16);
    margin: 119px 0 0;
    text-align: center;

    @include mixin.sp {
      font-size: 1.3rem;
      margin-top: 80px;
    }
  }
}

// ==================================================
//  Float CTA
// ==================================================
.floatCta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 11px;
  width: 107px;
  position: fixed;
  z-index: 90;
  right: 29px;
  bottom: 29px;

  @include mixin.sp {
    row-gap: 10px;
    width: 90px;
    right: 20px;
    bottom: 20px;
  }

  &_item {
    display: block;
    width: 100%;
    height: auto;
    @include mixin.hoverOpacity60;

    & > img {
      display: block;
      width: 100%;
    }
  }
}
